import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  updatedRequest: any;

  constructor(private localStorageServ: LocalStorageService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const userToken = this.localStorageServ.getItem('user_token') ? 'Bearer ' + this.localStorageServ.getItem('user_token') : ''

    const auth = request.headers.get('Authorization');

    if (auth) {
      this.updatedRequest = request.clone({
        headers: request.headers.set('Authorization', userToken),
        // headers: request.headers.set('Access-Control-Allow-Origin', '*'),
        // headers: request.headers.set('Content-Type', 'application/x-www-form-urlencoded'),
        withCredentials: true,
      });

    } else {
      this.updatedRequest = request.clone({
        withCredentials: true,
      });
    }

    return next.handle(this.updatedRequest);
  }
}
