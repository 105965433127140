// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


const baseOrigine = 'https://api.lokatout.fr/';

export const environment = {
  Api_Url: baseOrigine,
  recaptchaSiteKey:"6LemMu4aAAAAACu_y2JDhXbjrZW5BWMKLFiFp86K",
  apiKey:'AIzaSyCTwmQNMKpJt6fUEN8pxvHaQlDqGfI3uV8',
  production: true
};