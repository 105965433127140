<div class="forget-pass" #mymodal>
    <div class="modal-header">
        <p class="text-light-black header-title title m-0">  {{ 'CLIENT.forgotPassword' | translate }}   </p>
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="changePasswordForm">
            <!-- <div class="form-group">
                <label class="text-grey-white fs-14" >Old password</label>
                <input type="password" class="form-control" placeholder="Old password" formControlName="token">
            </div> -->
            <div class="form-group">
                <label class="text-grey-white fs-14" >  {{ 'CLIENT.newPassword' | translate }}  </label>
                <input type="password" class="form-control" placeholder="New password" formControlName="password">
            </div>
            <div class="form-group">
                <label class="text-grey-white fs-14" >  {{ 'CLIENT.confirmPassword' | translate }} </label>
                <input type="password" class="form-control" placeholder="Confirm password"
                    formControlName="confirmPassword">
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="chengePassword()">  {{ 'BUTTONS.save' | translate }} </button>
    </div>
</div>