import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  isSignedIn: boolean;

  constructor(
    private authServ: AuthService,
    private router: Router
  ) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const connected_user = this.authServ.isSignedIn();
    const allowedRoles = route.data.allowedRoles;
    const isAuthorized = this.authServ.isAuthorized(allowedRoles);
      
    if (isAuthorized) {
      return true;
    }
    
    if ( !connected_user ) {
        return true
    }
    
    // Case: user isSignedIn redirect to '/'
    this.router.navigate(['/dashboard']);
    return false;
  }

}
