import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(
    private authServ: AuthService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const allowedRoles = route.data.allowedRoles;
    const isAuthorized = this.authServ.isAuthorized(allowedRoles);
    
    if (isAuthorized) {
      return true;
    }

    this.router.navigate(['/']);
    return false;
    
  }

  // canActivate for module's children
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const allowedRoles = next.data.allowedRoles;
    const isAuthorized = this.authServ.isAuthorized(allowedRoles);


    if (isAuthorized) {
      return true;
    }

    this.router.navigate(['/']);
    return false;

  }


}
