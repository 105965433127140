import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedIn;

  url = environment.Api_Url;

  constructor(
    private http: HttpClient,
    private router: Router,
    private localStorageServ: LocalStorageService,
    private modalService: NgbModal,
    private toastr: ToastrService,
  ) { }

  /**
   * signin: Save user's data when cedentials data exists
   * @param credentialsData : user's data
   */
  signin(credentialsData: object) {

    return new Promise(resolve => {
      this.http.post(this.url + 'login', credentialsData,
      {
        headers: { Authorization: 'true' }
      }).subscribe((res: any) => {
        resolve({ status: true, data: res.data });
      }, (err) => {
        resolve({ status: false, error: err.error });
      });
    });
  }

  /**
   *
   * @param credentialsData : user's form data
   */
  register(credentialsData: object) {
    return new Promise(resolve => {
      this.http.post(this.url + 'users/signup', credentialsData,
      {
        headers: { Authorization: 'true' }
      }).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  managerRegister(credentialsData: object) {
    return new Promise(resolve => {
      this.http.post(this.url + 'users/manager/register', credentialsData,
      {
        headers: { Authorization: 'true' }
      }).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

  signout() {
    return this.http.get(this.url + 'users/logout', {}).subscribe(res => {
      this.localStorageServ.clearStorage();
    });
  }

  getConnectedUser() {
    return new Promise(resolve => {
      this.http.get(this.url + 'users/profile',
      {
        headers: { Authorization: 'true' }
      }).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        this.toastr.error('Votre session est terminée, veuillez vous connecter', '', {
          timeOut: 2000,
        });
        setTimeout(() => {
          this.localStorageServ.clearStorage();
        }, 3000);


        resolve({ status: false, error: err });
      });
    });
  }


  isSignedIn(): boolean {
    const userEmail = this.localStorageServ.getItem('user_email');
    return userEmail ? true : false;
  }

  /**
   * Check if user belongs to auth allowedRoles list
   * @param allowedRoles: string[]
   */
  isAuthorized(allowedRoles: string[]): boolean {

    let authorized = false;

    if (!allowedRoles) {
      authorized = true;
    }
    const user = this.localStorageServ.getItem('user_role');

    // Case: no user found
    if (user === null) {
      authorized = false;
    }
    if (allowedRoles.includes(user)) { authorized = true; }

    return authorized;
  }

  getUserRole() {
    const user = this.localStorageServ.getItem('user_role');
    return user ? user.role : null;
  }

  changePassword(newPasswordForm) {
    return new Promise(resolve => {
      this.http.patch(this.url + 'users/password', newPasswordForm,
      {
        headers: { Authorization: 'true' }
      }).subscribe((res: any) => {
        resolve({ status: true, data: res });
      }, (err) => {
        resolve({ status: false, error: err });
      });
    });
  }

}
