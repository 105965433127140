<div class="footer">

  <div class="container">

    <div class="row">
      <div class="col-md-3">

        <div class="category w-100">
          <div class="category-header">
            VÉHICULES
          </div>
          <div class="sub-category">
            <ul>
              <li>Voitures Motos</li>
              <li>Caravaning</li>
              <li>Utilitaires</li>
              <li>Camions Nautisme</li>
              <li>Équipement auto</li>
              <li>Équipement moto</li>
              <li>Équipement caravaning</li>
              <li>Équipement nautisme</li>
            </ul>
          </div>
        </div>



        <div class="category w-100">
          <div class="category-header">
            IMMOBILIER
          </div>
          <div class="sub-category">
            <ul>
              <li>Immobilier Neuf</li>
              <li>Locations</li>
              <li>Colocations</li>
              <li>Bureaux & Commerces</li>
            </ul>
          </div>
        </div>

        <div class="category w-100">
          <div class="category-header">
            VACANCES
          </div>
          <div class="sub-category">
            <ul>
              <li> Locations & Gîtes</li>
              <li>Chambres d'hôtes</li>
              <li>Campings</li>
              <li> Hébergements insolites</li>
              <li> Hôtels</li>
              <li> Ventes privées vacances</li>
            </ul>
          </div>
        </div>


        <div class="category w-100">
          <div class="category-header">
            LOISIRS
          </div>
          <div class="sub-category">
            <ul>
              <li>DVD - Films</li>
              <li>CD - Musique</li>
              <li>Livres</li>
              <li> Sports & Hobbies</li>
              <li> Instruments de musique</li>
              <li> Collection</li>
              <li> Jeux & Jouets</li>
              <li> Vins & Gastronomie</li>
            </ul>
          </div>
        </div>























      </div>



      <div class="col-md-3">

        <div class="category w-100">
          <div class="category-header">
            ANIMAUX
          </div>
          <div class="sub-category">
            <ul>
              <li>Animaux</li>
            </ul>
          </div>
        </div>



        <div class="category w-100">
          <div class="category-header">
            MODE
          </div>
          <div class="sub-category">
            <ul>
              <li>Vêtements</li>
              <li>Chaussures</li>
              <li>Accessoires & Bagagerie</li>
              <li>Montres & Bijoux</li>
              <li>Équipement bébé</li>
              <li>Vêtements bébé </li>
              <li>Luxe et Tendance</li>
            </ul>
          </div>
        </div>

        <div class="category w-100">
          <div class="category-header">
            MULTIMÉDIA
          </div>
          <div class="sub-category">
            <ul>
              <li> Informatique</li>
              <li>Consoles & Jeux vidéo</li>
              <li>Image & Son</li>
              <li> Téléphonie</li>
            </ul>
          </div>
        </div>


        <div class="category w-100">
          <div class="category-header">
            SERVICES
          </div>
          <div class="sub-category">
            <ul>
              <li>Prestations de services</li>
              <li>Billetterie</li>
              <li>Évènements</li>
              <li> Cours particuliers</li>
              <li> Covoiturage</li>
            </ul>
          </div>
        </div>


        <div class="category w-100">
          <div class="category-header">
            MAISON
          </div>
          <div class="sub-category">
            <ul>
              <li>Ameublement</li>
              <li>Électroménager</li>
              <li>Arts de la table</li>
              <li>Décoration</li>
              <li> Linge de maison</li>
              <li> Bricolage</li>
              <li> Jardinage</li>
            </ul>
          </div>
        </div>


      </div>


      <div class="col-md-3">


        <div class="category w-100">
          <div class="category-header">
            MATÉRIEL PROFESSIONNEL
          </div>
          <div class="sub-category">
            <ul>
              <li>Matériel agricole</li>
              <li>Transport - Manutention</li>
              <li> BTP - Chantier gros-oeuvre</li>
              <li>Outillage - Matériaux 2nd-oeuvre</li>
              <li> Restauration - Hôtellerie</li>
              <li> Fournitures de bureau</li>
              <li> Commerces & Marchés</li>
              <li>Matériel médical</li>
            </ul>
          </div>
        </div>



        <div class="category w-100">
          <div class="category-header">
            DIVERS
          </div>
          <div class="sub-category">
            <ul>
              <li>Autres</li>
            </ul>
          </div>
        </div>



      </div>
      <div class="col-md-3">


        <div class="category w-100">
          <div class="category-header">
            OUEST
          </div>
          <div class="sub-category">
            <ul>
              <li>Basse-Normandie</li>
              <li>Bretagne</li>
              <li>Pays de la Loire</li>
              <li>Poitou-Charentes</li>
              <li>SUD-OUEST</li>
            </ul>
          </div>
        </div>


        <div class="category w-100">
          <div class="category-header">
            OUEST
          </div>
          <div class="sub-category">
            <ul>
              <li>Midi-Pyrénées</li>
              <li>SUD-EST</li>
            </ul>
          </div>
        </div>


        <div class="category w-100">
          <div class="category-header">
            Corse
          </div>
          <div class="sub-category">
            <ul>
              <li>Languedoc-Roussillon</li>
              <li>Provence-Alpes-Côte d'Azur</li>
              <li>Rhône-Alpes</li>
              <li>EST</li>
            </ul>
          </div>
        </div>


        <div class="category w-100">
          <div class="category-header">
            Alsace
          </div>
          <div class="sub-category">
            <ul>
              <li>Bourgogne</li>
              <li>Champagne-Ardenne</li>
              <li>Franche-Comté</li>
              <li>Lorraine</li>
            </ul>
          </div>
        </div>



        <div class="category w-100">
          <div class="category-header">
            NORD
          </div>
          <div class="sub-category">
            <ul>
              <li>Haute-Normandie</li>
              <li>Nord-Pas-de-Calais</li>
              <li>Picardie</li>
            </ul>
          </div>
        </div>



      </div>

    </div>
  </div>

</div>

<div class="footer-after bg-blue">

  <div class="container">

    <div class="row">


      <div class="col-md-3 w-100">

        <div class="row">
          <div class="link-title  w-100">
            <h3> À PROPOS DU Lokatout </h3>
          </div>
          <div class="links  w-100">
            <a href="javascript:void(0)">Qui sommes-nous ?</a>
            <a href="javascript:void(0)">Nous rejoindre</a>
            <a href="javascript:void(0)">Acteur responsable de l’économie française</a>
          </div>
        </div>


        <div class="row">
          <div class="link-title w-100">
            <h3> NOS APPLICATIONS </h3>
          </div>
          <div class="links  w-100">
            <a href="javascript:void(0)"> Télécharger l’application iOS</a>
            <a href="javascript:void(0)">Télécharger l’application Android</a>
          </div>
        </div>

      </div>


      <div class="col-md-3">

        <div class="row w-100">
          <div class="link-title  w-100">
            <h3> INFORMATIONS LÉGALES </h3>
          </div>
          <div class="links  w-100">
            <a href="javascript:void(0)"> Conditions générales d’utilisation </a>
            <a href="javascript:void(0)"> Règles de diffusion, de référencement et de déréférencement </a>
            <a href="javascript:void(0)"> Conditions générales de vente</a>
            <a href="javascript:void(0)"> Vie privée / cookies</a>
            <a href="javascript:void(0)"> Vos droits et obligations </a>
            <a href="javascript:void(0)"> Critères de classement</a>
            <a href="javascript:void(0)"> Décisions de justice </a>
          </div>
        </div>

      </div>



      <div class="col-md-3">

        <div class="row w-100">
          <div class="link-title  w-100">
            <h3> NOS SOLUTIONS PROS </h3>
          </div>
          <div class="links  w-100">
            <a href="javascript:void(0)"> Publicité </a>
            <a href="javascript:void(0)"> Professionnels de l’immobilier</a>
            <a href="javascript:void(0)"> Vos recrutements </a>
            <a href="javascript:void(0)"> Professionnels de l’auto</a>
            <a href="javascript:void(0)"> Professionnels du tourisme </a>
            <a href="javascript:void(0)"> Autres solutions professionnelles </a>
            <a href="javascript:void(0)"> Annuaire des professionnels </a>
            <a href="javascript:void(0)"> Dépôt gratuit d’emploi pour les TPE </a>
          </div>
        </div>

      </div>


      <div class="col-md-3">

        <div class="row w-100">
          <div class="link-title  w-100">
            <h3> DES QUESTIONS ?</h3>
          </div>
          <div class="links  w-100">
            <a href="javascript:void(0)"> Aide </a>
            <a href="javascript:void(0)"> Le service de paiement sécurisé et la livraison </a>
            <a href="javascript:void(0)"> Le service de réservation de vacances en ligne pour les hôtes </a>
            <a href="javascript:void(0)"> Demandez conseil à la Communauté </a>
            <a href="javascript:void(0)"> Statut de nos services</a>
          </div>
        </div>

      </div>


    </div>

  </div>
</div>

<div class="footer-copy-right">
  <div class="col-md-12 text-center">
    <p class="m-0"> Copyright © 2021 Genext-IT All rights reserved </p>
  </div>
</div>
