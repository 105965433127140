import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  @Output() changePasswordForm: FormGroup;

  constructor(
    private modalService: NgbModal,
    private authServ: AuthService,
    private toastr: ToastrService,
    public translate: TranslateService,
    ) { }

  ngOnInit(): void {
    this.changePasswordForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
    })
  }

  close() {
    this.modalService.dismissAll();
  }

  chengePassword() {
    this.authServ.changePassword(this.changePasswordForm.value).then((res: any) => {
      if (res.status) {
        this.modalService.dismissAll();
        this.toastr.success(this.translate.instant('TOASTR.changePasswordSuccess'), this.translate.instant('TOASTR.Success'), {
          timeOut: 1500
        });
      } else {
        res.error.error.message.errors.forEach(element => {
          this.toastr.error(element.msg, this.translate.instant('TOASTR.Error'), {
            timeOut: 1500
          });
        });
      }
    })
  }

}
