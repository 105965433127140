import { RouterModule } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';


import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpErrorInterceptor } from '../core/interceptor/http-error.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SigninComponent } from './signin/signin.component';


import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';

import { AddressInputComponent } from './address-input/address-input.component';


import { RecaptchaModule } from 'ng-recaptcha';

import { ImageCropperModule } from 'ngx-image-cropper';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

import { SharedModule } from '../shared/shared.module';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FooterComponent } from './footer/footer.component';
import { UserFormComponent } from './forms/user-form/user-form.component';
import { AdvFormComponent } from './forms/adv-form/adv-form.component';
import { RentFormComponent } from './rent-form/rent-form.component';
import { CategoryFormComponent } from './forms/category-form/category-form.component';
import { RentListComponent } from './rent-list/rent-list.component';
import { AdverListComponent } from './adver-list/adver-list.component';


@NgModule({
  declarations: [
    NavbarComponent,

    SigninComponent,
    AddressInputComponent,
    FooterComponent,

    UserFormComponent,
    AdvFormComponent,
    RentFormComponent,
    CategoryFormComponent,
    RentListComponent,
    AdverListComponent,

  ],
  imports: [
    CommonModule,
    MatFormFieldModule,


    SharedModule,
    SlickCarouselModule,

    MatSelectModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatDatepickerModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    RouterModule,
    RecaptchaModule,
    ImageCropperModule
  ],
  exports: [
    NavbarComponent,

    SigninComponent,
    AddressInputComponent,
    FooterComponent,
    UserFormComponent,
    AdvFormComponent,
    RentFormComponent,
    CategoryFormComponent,
    RentListComponent,
  ],
  entryComponents: [
    SigninComponent,
    FooterComponent,
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true
  }
  ],
})
export class LayoutsModule { }
