import { Component, OnInit } from '@angular/core';
import { DatePipe, DOCUMENT } from '@angular/common';
import { AuthService } from './core/auth/auth.service';
import { Title, Meta } from '@angular/platform-browser';

declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        DatePipe,
    ]
})
export class AppComponent implements OnInit {
    constructor(
        public datepipe: DatePipe,
        private authServ: AuthService,
        private titleService: Title,
        private metaTagService: Meta,
    ) {
    }

    ngOnInit() {

        this.authServ.getConnectedUser();



        this.titleService.setTitle('Lokatout');

        this.metaTagService.addTags([
        {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1'
        },
        {
            name: 'robots',
            content: 'noindex, unfollow'
        }
        ]);
    }

}


