import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninGuard } from './core/guard/signin/signin.guard';
import { Page404Component } from './pages/page404/page404.component';
import { RoleGuard } from './core/guard/role/role.guard';
import { LoginGuard } from './core/guard/login/login.guard';
import { TokenGuard } from './core/guard/token/token.gard';


const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./front/front.module').then(m => m.FrontModule),
    canActivate: [LoginGuard],
    data: { allowedRoles: ['CLIENT'] }

  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [SigninGuard,RoleGuard],
    data: { allowedRoles: ['ADMIN'] }
  },
  {
    path: 'not-found',
    component: Page404Component
  },

  {
    path: '**',
    redirectTo: 'not-found'
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
