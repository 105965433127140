import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';
@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss']
})
export class Page404Component implements OnInit {

  constructor(
    public translate: TranslateService,
    private localStorageServ: LocalStorageService,
  ) { 
    translate.addLangs(['en', 'fr']);
    if (this.localStorageServ.getItem('lang')) {
      translate.setDefaultLang(this.localStorageServ.getItem('lang'));
    } else {
      navigator.language.includes('en')
        ? translate.setDefaultLang('en')
        : translate.setDefaultLang('fr');
    }
  }

  ngOnInit(): void {
  }

}
